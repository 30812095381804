import React from 'react';
import about from "../images/about.jpg"

const About = () => {
    const data_fr = {
        name: "Nidaglass",
        description: "En tant que Président-Directeur Général de la société NIDAGLASS, c'est avec un grand plaisir de vous accueillir sur notre site web officiel.\n" +
            "Notre société est 100% familiale. Elle est présente sur le marché du verre marocain depuis 2018. Nous avons construit son développement et sa réputation sur l’excellence de ses services et sur la qualité de ses produits.\n" +
            "\n" +
            "Notre clientèle considère notre société NIDAGLASS comme une référence dans l'industrie du VERRE.\n" +
            "\n" +
            "Notre ambition est de continuer le développement de notre entreprise, de maintenir la réactivité, de développer notre relation client ainsi que la prospection et le recrutement de nouveaux marchés.\n" +
            "\n" +
            "Nous sommes dans Marches de notre trajectoire avec sérénité et consistance. Chaque jours nous nous projetons dans l'avenir, grâce à son dynamisme et sa volonté d'indépendance.\n" +
            "\n" +
            "Nous nous sommes entièrement engagés avec nos équipes.\n" +
            "Nos collaborateurs, notre atelier et nos espaces logistiques, sont mobilisés afin de vous offrir un service réactif, intégré et de qualité."
    }
    return (
        <div className="w-full lg:container mx-auto">
            <div className="pt-16 flex items-center">
                <div className="lg:flex py-8">
                    <div className="px-4 flex items-center">
                        <img id="about-pic" src={about} alt="Rachid hamma ali" className="flex shadow-xl max-h-96 rounded-xl shadow-lg"/>
                    </div>
                    <div id="profiledescription" className="text-color">
                        <h1 className="px-4 py-2 font-bold text-2xl text-left">{data_fr.name}</h1>
                        <p className="px-4 py-2 text-left max-w-2xl">{data_fr.description}</p>
                        {/*<div className="p-4 flex mx-auto">*/}
                        {/*    <a href="https://www.linkedin.com/in/rhmx/" target="_blank" rel="noreferrer"><img src={linkedin} alt="Rachid hamma ali linkedIn profile" className="social-icons p-1 rounded-md"/></a>*/}
                        {/*    <a href="https://github.com/rhmx01" target="_blank" rel="noreferrer"><img src={github} alt="Rachid hamma ali github profile" className="social-icons p-1 rounded-md"/></a>*/}
                        {/*    <a href="https://rhmx01.medium.com" target="_blank" rel="noreferrer"><img src={medium} alt="Rachid hamma ali medium profile" className="social-icons p-1 rounded-md"/></a>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;